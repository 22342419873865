import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmbeddedBiResult } from 'types/DataResult';
import { Observable } from 'rxjs';
import { RESTServerRoute } from 'types/Server';

@Injectable()
export class EmbeddedPowerBiApiService {
  public constructor(private readonly httpClient: HttpClient) {}

  public getPowerBiToken(params: { reportName: string }): Observable<EmbeddedBiResult> {
    return this.httpClient.post<EmbeddedBiResult>(RESTServerRoute.REST_GENERATE_TOKEN, params);
  }

  public getRefreshId(params: { reportName: string }): Observable<EmbeddedBiResult> {
    return this.httpClient.post<EmbeddedBiResult>(RESTServerRoute.REST_IMPORT_AND_REFRESH_REPORT, params);
  }

  public getReports(): Observable<string[]> {
    return this.httpClient.get<string[]>(RESTServerRoute.REST_GET_AVAILABLE_REPORTS);
  }
}
